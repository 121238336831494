document.addEventListener("DOMContentLoaded", function () {

    if ("IntersectionObserver" in window) {


        if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            return;
        }

        const observableItems = document.querySelectorAll(".asym-images .text");

        observableItems.forEach((item) => {
            item.classList.add("isObservable");
        });

        const gridRowTextObserver = new IntersectionObserver((entries, textObserver) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const currentItem = entry.target;
                    //console.log("Item insecting is: ", currentItem);
                    currentItem.classList.remove("isObservable");
                    textObserver.unobserve(currentItem);
                }
            });
        });

        observableItems.forEach((item) => {
            gridRowTextObserver.observe(item);
        });


    } else {
        console.log("Sorry, but your browser is ancient and does not support IntersectionObserver");
    }

});